import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Column,
  Row,
  Button,
  Breadcrumbs,
  Input,
  Section,
  List,
  ListItem,
} from '@nimles/react-web-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Divider } from '@nimles/react-web-components/src/Divider';
import {
  ProductList,
  ProductGroupList,
} from '@nimles/react-web-components-redux';
import { ProductFacetSelector } from '../components/ProductFacetSelector';
import { RootState } from '../redux/types';
import { DefaultStaticQuery } from '../components/DefaultStaticQuery';
import queryString from 'query-string';
import styled from '@emotion/styled';
import { Link } from 'gatsby-plugin-react-i18next';
import Layout from '../layouts/Layout';
import { PageText } from '../components/PageText';
import { BannerSection } from '../components/banner/BannerSection.carware';
import { OrganizationModel } from '@nimles/models';

const getParents = (productGroup: any, productGroups: any[]) => {
  let parents: any[] = [];
  if (!productGroup) {
    return parents;
  }
  if (productGroup.parentId) {
    const parent = productGroups.find(
      (pg: any) => pg.id === productGroup.parentId
    );

    parents = getParents(parent, productGroups);
  }

  parents.push(productGroup);

  return parents;
};

const getBreadcrumbs = (productGroup: any, productGroups: any[]) =>
  getParents(productGroup, productGroups).map((parent) => {
    const part = {
      name: parent.name,
      to: '/' + parent.uniqueName,
    };

    return part;
  });

export const CatalogPage = ({ pim, cms, location, site }) => {
  const { t } = useTranslation();
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(24);
  const [order, setOrder] = useState('name');
  const { search } = location;
  const { productGroupUniqueName }: any = search && queryString.parse(search);

  const page = cms.pages.edges
    .map(({ node }) => node)
    .find((p) => p.uniqueName === 'catalog');
  const productGroups = pim.productGroups.edges.map(({ node }) => node);
  const organization = useSelector<RootState, OrganizationModel>(
    ({ publicOrganizations }) => publicOrganizations.selected
  );
  const productGroup = productGroups.find(
    (pg: any) => pg.uniqueName === productGroupUniqueName
  );

  const handleLimitChange = (e) => {
    e.preventDefault();
    setLimit(Number(e.target.value));
  };

  const handleOrderChange = (e) => {
    e.preventDefault();
    setOrder(e.target.value);
  };

  const Select = styled.select`
    margin-bottom: 10px;
  `;

  return (
    <Layout header location={location}>
      <BannerSection round></BannerSection>
      <Section>
        <Container padding>
          <PageText page={page} uniqueName="start-text" center />
          <Row justify="center" margin="20px -15px">
            <Column xs={100} md={90} lg={80} xl={70} xxl={60}>
              <Input placeholder="Sök efter din önskade reservdel" />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row wrap>
            {productGroups
              .filter(({ parentId }) => !parentId)
              .map(({ name, id, children, uniqueName }) => (
                <Column key={id} xs={100} sm={50} lg={33.33} xl={25}>
                  <Link to={`/${uniqueName}-pg`}>
                    <h3>{name}</h3>
                  </Link>
                  <List>
                    {children?.slice(0, 5).map(({ id, name, uniqueName }) => (
                      <ListItem key={id}>
                        <Link to={`/${uniqueName}-pg`}>{name}</Link>
                      </ListItem>
                    ))}
                  </List>
                </Column>
              ))}
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

const Catalog = ({ location }) => (
  <DefaultStaticQuery
    render={(props) => <CatalogPage {...props} location={location} />}
  />
);

export default Catalog;
